import React, { Component as ReactComponent, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import NavBar from './header/nav-bar/NavBar';
import LeftSideBar from './header/LeftSideBar';
import { InstrumentList } from '../instruments';
import Archive from './archive/Archive';
import PasswordChange from './auth/PasswordChange';
import FileOperationStatusPanel from './file_operations/FileOperationStatusPanel';
import UserProfile from './auth/UserProfile';
import CustomerSupport from './contact/CustomerSupport';
import routes, { ROUTE_NOT_FOUND } from '../core/routes';
import conventionalPcrRoutes from '../conventional-pcr-frontend/routes';
import PageNotFound from '../frontend-common-libs/src/components/pages/PageNotFound';
import CreateExperiment from './create-experiment/CreateExperiment';
import Home from './home/Home';
import IoTInitializer from './IoTInitializer';
import EarlyAccessProgram from '../eap';
import { UserFiles } from '../file-management';
import {
  InstrumentFamilyPluginRepository,
  InstrumentFamilyPluginRepositoryInitializer,
  withPluginComponentFactory
} from '../instrument-family-plugin';
import RunReportTab from '../conventional-pcr-frontend/completed-run/run/report/RunReportTab';
import FeatureFlags, {
  FeatureFlagKeys
} from '../frontend-common-libs/src/components/feature_flags';
import Fleet from '../fleet-management/components/Fleet';
import OrgRouter from '../organization-management/router/OrgRouter';
import Settings from '../settings-page';

type ContainerProps = {
  children: ReactElement;
};

function Container(props: ContainerProps) {
  const { children } = props;
  return (
    <>
      <NavBar />
      <div className="page-content">
        <LeftSideBar />
        <div className="right-panel">{children}</div>
        <FileOperationStatusPanel />
      </div>
    </>
  );
}

type Props = {};
type State = {
  pluginsInitialized: boolean;
};

export default class App extends ReactComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pluginsInitialized: false
    };
  }

  onPluginsInitialized = () => {
    this.setState({
      pluginsInitialized: true
    });
  };

  render() {
    const { pluginsInitialized } = this.state;
    return (
      <InstrumentFamilyPluginRepositoryInitializer onPluginsInitialized={this.onPluginsInitialized}>
        <Switch>
          <Route
            exact
            path={`${routes.APP}${conventionalPcrRoutes.VIEW_PCR_RUN_REPORT}`}
            component={RunReportTab}
          />
          <Container>
            <Switch>
              <Route exact path={routes.HOME} component={Home} />
              <Route exact path={routes.INSTRUMENT_LIST} component={InstrumentList} />
              <Route exact path={routes.FILE_LIST} component={UserFiles} />
              <Route exact path={routes.ARCHIVE} component={Archive} />
              <Route exact path={routes.CREATE_EXPERIMENT} component={CreateExperiment} />
              <Route exact path={routes.CONTACT} component={CustomerSupport} />
              <Route exact path={routes.CHANGE_PASSWORD} component={PasswordChange} />
              <Route exact path={routes.USER_PROFILE} component={UserProfile} />
              {FeatureFlags().get(FeatureFlagKeys.SETTINGS_PAGE) && (
                <Route exact path={routes.SETTINGS} component={Settings} />
              )}
              <Route exact path={routes.FLEET} component={Fleet} />
              {OrgRouter.instance.getOrganizationRouts()}
              <Route exact path={routes.EARLY_ACCESS_PROGRAM} component={EarlyAccessProgram} />
              {pluginsInitialized &&
                InstrumentFamilyPluginRepository.getInstance()
                  .getAll()
                  .map(plugin => {
                    const PluginApp = withPluginComponentFactory(plugin.getApp, {
                      rootPath: routes.APP
                    });
                    return (
                      <Route
                        key={plugin.name}
                        path={`${routes.APP}${plugin.basePath}`}
                        render={PluginApp}
                      />
                    );
                  })}
              <Route path={ROUTE_NOT_FOUND} component={PageNotFound} />
            </Switch>
          </Container>
        </Switch>
        <IoTInitializer />
      </InstrumentFamilyPluginRepositoryInitializer>
    );
  }
}
