import { ProjectRoles } from '@biorad-lsg-tsc/organization';
import request from '../../frontend-common-libs/src/utils/httpUtils';
import { Project, ProjectId } from '../../frontend-common-libs/src/common/project-management-types';
import { ProjectDetails } from './types';
import {
  baseURL,
  accessControlBasePath,
  getUserTokenHeader
} from '../../organization-management/api/access-control';
import FeatureFlags, {
  FeatureFlagKeys
} from '../../frontend-common-libs/src/components/feature_flags';

export function basePath() {
  if (FeatureFlags().get(FeatureFlagKeys.PROJECTS_V2)) return `${accessControlBasePath}/project-v2`;
  return `${accessControlBasePath}/project`;
}

export type GetProjectsResponse = { projects: Project[]; orgName: string };

export async function getProjects(
  orgId: string,
  accessToken: string
): Promise<GetProjectsResponse> {
  const path = `${basePath()}/user-projects/${orgId}`;
  const response = await request.get(baseURL, {}, path, getUserTokenHeader(accessToken));
  return response.data;
}

export async function createProject(
  projectName: string,
  orgId: string,
  userToken: string
): Promise<Project> {
  const path = `${basePath()}`;
  const body = { projectName, orgId };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data.project;
}

export async function addUserToProject(
  projectId: ProjectId,
  orgId: string,
  userEmail: string,
  userRole: ProjectRoles,
  userToken: string
): Promise<any> {
  const path = `${basePath()}/add-user`;
  const body = { projectId, orgId, userEmail, userRole };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function getProjectDetails(
  projectId: ProjectId,
  accessToken: string
): Promise<ProjectDetails> {
  const path = `${basePath()}/details/${projectId}`;
  const response = await request.get(baseURL, {}, path, getUserTokenHeader(accessToken));
  return response.data;
}

export async function getProjectAccessToken(
  projectId: string,
  orgAccessToken: string
): Promise<any> {
  const path = `${basePath()}/access-token/${projectId}`;
  const response = await request.get(baseURL, {}, path, getUserTokenHeader(orgAccessToken));
  return response.data;
}

export async function setProjectMemberRole(
  projectId: string,
  userEmail: string,
  userRole: string,
  accessToken: string
): Promise<any> {
  const path = `${basePath()}/assign-role`;
  const body = { projectId, userEmail, userRole };
  const response = await request.put(baseURL, {}, path, getUserTokenHeader(accessToken), body);
  return response.data;
}

export async function removeUserFromProject(
  projectId: ProjectId,
  userEmail: string,
  userToken: string
): Promise<any> {
  const path = `${basePath()}/remove-user`;
  const body = { projectId, userEmail };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function renameProject(
  projectId: ProjectId,
  name: string,
  userToken: string
): Promise<any> {
  const path = `${basePath()}/edit`;
  const body = { projectId, projectName: name };
  const response = await request.put(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}

export async function refreshProjectAccessToken(userToken: string, orgId: string): Promise<any> {
  const path = `${basePath()}/refresh-access-token`;
  const body = { orgId };
  const response = await request.post(baseURL, {}, path, getUserTokenHeader(userToken), body);
  return response.data;
}
