import React from 'react';
import className from 'classnames';
import { connect } from 'react-redux';
import expandImage from '../../../img/expand-icon-white.svg';
import collapseImage from '../../../img/collapse-icon-white.svg';
import ExternalAnchorLink from '../../frontend-common-libs/src/components/common/buttons/ExternalAnchorLink';
import { ReduxState } from '../../types';
import routes from '../../core/routes';
import HelpCenter, { docHelpPaths } from '../../utils/HelpCenter';

type SideBarProps = {
  username: string;
};
type SideBarState = {
  isExpanded: boolean;
};

export class ExploreSideBarImpl extends React.Component<SideBarProps, SideBarState> {
  static CONTENT_VERSION = 2;

  private helpCenter: HelpCenter;

  toggleRef: any;

  constructor(props: SideBarProps) {
    super(props);
    const userSettings = this._getUserSettings();

    let isExpanded = true;
    let isOldVersion = false;

    const exploreSidebar = userSettings && userSettings.exploreSidebar;
    if (exploreSidebar) {
      const { version: userCurrentVersion, isExpanded: userCurrentIsExpanded } = exploreSidebar;
      isOldVersion = userCurrentVersion < ExploreSideBarImpl.CONTENT_VERSION;
      isExpanded = isOldVersion ? true : userCurrentIsExpanded;
    }

    if (!exploreSidebar || isOldVersion) {
      this.setExploreSidebarLocalStorage(ExploreSideBarImpl.CONTENT_VERSION, true);
    }
    this.state = { isExpanded };
    this.toggleRef = React.createRef();
    this.helpCenter = new HelpCenter();
  }

  _getUserSettings() {
    const { username } = this.props;
    return JSON.parse(window.localStorage.getItem(username) || '{}');
  }

  onExpandButtonClick = () => {
    if (this.toggleRef.current) this.toggleRef.current.focus();

    this.setState(
      state => ({ isExpanded: !state.isExpanded }),
      () => {
        const { isExpanded } = this.state;
        this.setExploreSidebarLocalStorage(ExploreSideBarImpl.CONTENT_VERSION, isExpanded);
      }
    );
  };

  setExploreSidebarLocalStorage = (version: number, isExpanded: boolean): void => {
    const { username } = this.props;
    window.localStorage.setItem(
      username,
      JSON.stringify({
        ...this._getUserSettings(),
        exploreSidebar: {
          version,
          isExpanded
        }
      })
    );
  };

  renderPaths = () => (
    <>
      <ExternalAnchorLink
        id="managing-cfx-instrument"
        href={this.helpCenter.helpUrl(
          '/Output/CFX_Opus/Content/CloudUA/PCR/Instruments/Managing_CFX_Instruments.htm'
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        Using your CFX Opus with BR.io
      </ExternalAnchorLink>
      <ExternalAnchorLink
        id="managing-ptc-instrument"
        href={this.helpCenter.helpUrl(
          '/Output/PTC_Tempo/Content/CloudUA/PCR/Instruments/Managing_PTCTempo_Instruments.htm'
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        Using your PTC Tempo with BR.io
      </ExternalAnchorLink>
      <ExternalAnchorLink
        id="managing-cdgo-instrument"
        href={this.helpCenter.helpUrl(
          '/Output/ChemiDoc_Go/Content/CloudUA/Imaging/ChemiDoc/Managing_CDG_Instruments.htm'
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        Using your ChemiDoc Go with BR.io
      </ExternalAnchorLink>
    </>
  );

  renderExpandedSideBar = () => (
    <div className="scroll-section">
      <h2 id="explore-sidebar-title">EXPLORE BR.io</h2>
      <p id="explore-sidebar-description">
        Welcome to BR.io! Check out the topics below to help you get started using the platform.
      </p>
      <ExternalAnchorLink
        id="getting-started-link"
        href={this.helpCenter.helpUrl(docHelpPaths.Home)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Getting Started
      </ExternalAnchorLink>
      {this.renderPaths()}
      <div>
        <ExternalAnchorLink
          id="eap-program-link"
          href={routes.EARLY_ACCESS_PROGRAM}
          target="_blank"
          rel="noopener noreferrer"
        >
          Join the Early Access Program
        </ExternalAnchorLink>
      </div>

      <br />
      <br />
      <div>
        <h2 id="explore-sidebar-title">{`What's New`}</h2>
        <p id="explore-sidebar-description">
          Available today! BR.io Projects enables you to share runs and protocols with other users.
        </p>
        <ExternalAnchorLink
          id="organization-link"
          href={this.helpCenter.helpUrl(docHelpPaths.Organization)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Sharing
        </ExternalAnchorLink>
        <div>
          <br />
          <p id="explore-sidebar-description">
            Introducing BR.io Fleet Management. Reserve and remotely control multiple PTC Tempo
            instruments. Available today!
          </p>
          <ExternalAnchorLink
            id="fleet-management-link"
            href={this.helpCenter.helpUrl(docHelpPaths.Fleet_Management)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Fleet Management
          </ExternalAnchorLink>
        </div>
      </div>
    </div>
  );

  renderToggleButton = (isExpanded: boolean) => {
    const expandCollapseImg = isExpanded ? collapseImage : expandImage;
    const expandCollapseTitle = isExpanded ? 'collapse' : 'expand';
    return (
      <div className="no-scroll-section">
        <button
          ref={this.toggleRef}
          id="explore-sidebar-toggle"
          type="button"
          className="expand-btn"
          onClick={isExpanded ? this.onExpandButtonClick : undefined}
        >
          <img
            id="toggle-image"
            src={expandCollapseImg}
            className="expand-img"
            alt={expandCollapseTitle}
            title={expandCollapseTitle}
          />
        </button>
      </div>
    );
  };

  render() {
    const { isExpanded } = this.state;
    return (
      <div
        className={className('explore-sidebar', {
          expanded: isExpanded
        })}
        onClick={!isExpanded ? this.onExpandButtonClick : undefined}
        role="presentation"
      >
        {this.renderToggleButton(isExpanded)}
        {isExpanded ? (
          this.renderExpandedSideBar()
        ) : (
          <h4 id="explore-sidebar-title-collapsed">
            EXPLORE
            <br />
            BR.io
          </h4>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    username: state.auth.get('username')
  };
}

export default connect(mapStateToProps)(ExploreSideBarImpl);
