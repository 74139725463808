import React from 'react';
import { RouteTitle } from '../../../../plugin-types';

export type Props = {
  routeTitle: RouteTitle;
  className: string;
};

export default function PageTitle({ routeTitle, className }: Props) {
  return routeTitle ? (
    <h1 id="page-title" className={className}>
      {routeTitle}
    </h1>
  ) : null;
}
